$(document).ready(function() {
  $('.js-tab-switch').on('click', function(){
    var _this = $(this);
    var tabId = _this.data('tabid');
    
    $('.js-tab-switch').removeClass('active');
    _this.addClass('active');
    
    $('.app-jobs-tab').hide();
    $(`#${tabId}`).show();
  })
  
})